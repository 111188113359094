import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import ContactBanner from "../components/ContactBanner"

export default function Partners(props) {
  return (
    <Layout>
      <Seo title="Partners" />
      <section className="wrapper container mt-12 md:mt-16 mb-8">
        <h1>Partnerships</h1>
      </section>
      <section className="wrapper bg-primary text-white mb-16">
        <div className="container py-4 lg:py-8 grid grid-cols-1 lg:grid-cols-2 gap-x-0 lg:gap-x-20 gap-y-4 lg:gap-y-0">
          <div className="flex flex-col">
            <div className="my-auto space-y-8">
              <StaticImage
                src="../images/bdaa-inverse.png"
                alt="Logo of the Building Designers Association of Australia."
              />
              <h2 className="w-80">
                Building Designers Association of Australia
              </h2>
              <div className="space-y-2">
                <p>
                  The Building Designers Association of Australia (BDAA) is
                  Australia's national association that ultimately aims to
                  advocate and represent building designers. It is a widely
                  recognised and revered organisation whose membership and
                  partners include prominent writers and authors alongside award
                  winning designers.
                </p>
                <p>
                  The BDAA has often been cited as an influential force and
                  consultant on nationwide building reform in a continuous
                  effort to improve and protect Australia's built environment.
                  Furthermore, the organisation host an industry awards program,
                  along with meetings, webinars, socials, and opportunities for
                  Continuing Professional Development (CPD) education and
                  advancement.
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <StaticImage src="../images/bdaa-01.JPEG" alt="" />
            <StaticImage src="../images/bdaa-02.jpg" alt="" />
            <StaticImage src="../images/bdaa-03.jpg" alt="" />
            <StaticImage src="../images/bdaa-04.JPG" alt="" />
            <StaticImage src="../images/bdaa-05.JPG" alt="" />
            <StaticImage src="../images/bdaa-06.JPG" alt="" />
          </div>
        </div>
      </section>
      <ContactBanner />
    </Layout>
  )
}
